/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Toggle Menu on Mobile
  $('#navbar-toggler').bind('click', function(e) {
    $('#navbar-collapse').slideToggle("800");
    $('#navbar-toggler i').toggleClass( 'fa-times');
    $('#navbar-toggler i').toggleClass( 'fa-bars');
    if ($('#search-dropdown').css('display') === 'block')
    {
       $('#search-dropdown').css('display','none');
    }
  });

  // Toggle Search bar
  $('#search-toggler').bind('click', function(e){
    $('#search-dropdown').slideToggle("800");
    $('.collapse').css('display','none');
    if($( "#navbar-toggler i" ).hasClass( "fa-times" )) {
      $('#navbar-toggler i').removeClass( 'fa-times');
      $('#navbar-toggler i').addClass( 'fa-bars');
    }
  });

  $('#bio-pub a').click(function (e) {
    e.preventDefault();
    $(this).tab('show');
  });

  // Tooltip
$('.copy-btn').tooltip({
  trigger: 'click',
  placement: 'top'
});

function setTooltip(message) {
  $('.copy-btn').tooltip('hide')
    .attr('data-original-title', message)
    .tooltip('show');
}

function hideTooltip() {
  setTimeout(function() {
    $('.copy-btn').tooltip('hide');
  }, 1000);
}

// Clipboard
var clipboard = new Clipboard('button');

clipboard.on('success', function(e) {
  setTooltip('Copied!');
  hideTooltip();
});

clipboard.on('error', function(e) {
  setTooltip('Failed!');
  hideTooltip();
});

// Citation Replace last comma with ampersand
$("#citations div:nth-last-child(4)").replaceWith( " & " );

$(document).ready(function(){
  $('.owl-carousel').owlCarousel({
    items: 1,
    nav: true,
    autoplay: true,
    loop: true,
    autoplayTimeout: 10000,
    navText : ["<img src='/wp-content/uploads/2017/12/slider-arrows-round-left.png'>","<img src='/wp-content/uploads/2017/12/slider-arrows-round-right.png'>"],
    onInitialized: function () {
        console.log("Initialize call event");
        $('.owl-carousel .owl-item').attr('aria-selected','false');
        $('.owl-carousel .owl-item.active').attr('aria-selected','true'); // let screen readers know an item is active
     
        // apply meta info to next and previous buttons and make them focusable
        $('.owl-carousel .owl-prev').attr('role','button').attr('title','Previous');
        $('.owl-carousel .owl-next').attr('role','button').attr('title','Next');
        $('.owl-carousel .owl-dot').attr('role','link');
        $('.owl-carousel, .owl-prev, .owl-next, .owl-dot').attr('tabindex','0');

        // add instructions to keyboard users that are only visible when the carousel is focused
        $('.owl-carousel .owl-wrapper-outer').append('');
    }
  });
});

//menu dropdown on show on hover
$('li.dropdown').on('click', function() {
  var $el = $(this);
  if ($el.hasClass('open')) {
      var $a = $el.children('a.dropdown-toggle');
      if ($a.length && $a.attr('href')) {
          location.href = $a.attr('href');
      }
  }
});

// menu with dropdown on click
$("#menu-item-24 a.link").on('click', function(){
  window.location = "/research";    
});
$("#menu-item-407 a.link").on('click', function(){
  window.location = "/about";    
});

//People Single Page Pagination for Publications
$('#bio-pub a').click(function(e) {
  e.preventDefault();
  $(this).tab('show');
});

// store the currently selected tab in the hash value
$("ul.nav-pills > li > a").on("shown.bs.tab", function(e) {
  var id = $(e.target).attr("href").substr(1);
  window.location.hash = id;
});

// on load of the page: switch to the currently selected tab
var hash = window.location.hash;
$('#bio-pub a[href="' + hash + '"]').tab('show');

// var prev_href = $(".prev-posts-link a").attr("href");
// var next_href = $(".next-posts-link a").attr("href");
//var ajax_url = window.location.protocol + "//" + window.location.host +'/aaafoundaion/wp-admin/admin-ajax.php';

// $("input[type=radio][id=all]").click(function () {
//   //if($(this).prop("checked")) { alert("checked!"); }
//   $(".prev-posts-link a").attr("href", prev_href + '?category=all');
//   $(".prev-posts-link a").attr("href", next_href + '?category=all');
// });

// $(function(){
//   var $mainContent = $('#main-content'),
//       $cat_links = $('ul.categories-filters li a');

//       $cat_links.on('click', function(e){
//         e.preventDefault(); //stops default behavior
//         $el = $(this);
//         var value = $el.attr("href"); 
//         $mainContent.animate({opacity: "8.5"});
//         $mainContent.load(value + " #inside", function(){
//           $mainContent.animate({opacity: "1"}); 
//         });
//       });
// });

})(jQuery); // Fully reference jQuery after this point.